<template>
    <div class="infoTable">
        <div class="slider">
            <el-tree
                :data="treeData"
                :props="defaultProps"
                :highlight-current="true"
                :expand-on-click-node="false"
                node-key="id"
                :default-expanded-keys="defaultKey"
                :current-node-key="currentNodeKey"
                class="tree-con"
                ref="tree"
            >
                <span class="slot-t-node" slot-scope="{ node, data }">
                    <div class="parent-node" v-if="data.directory_type===0">
                        <i v-if="node.expanded" class="iconfont iconshouqi" @click.stop="expandtree(node)"></i>
                        <i v-if="!node.expanded" class="iconfont iconzhankai" @click.stop="expandtree(node)"></i>
                        <span v-if="node.expanded" class="iconfont iconwenjianjia-zhankai" @click.stop="expandtree(node)"></span>
                        <span v-if="!node.expanded" class="iconfont iconwenjianjia-shouqi" @click.stop="expandtree(node)"></span>
                        <span>{{ data.name }}</span>
                    </div>
                    <div class="leaf-node" v-else @click="loadTable(data.entity_id)">
                        <span class="iconfont iconlingxing"></span>
                        <span>{{ data.name }}</span>
                    </div>
                </span>
            </el-tree>
        </div>
        <div class="table-con">
            <el-table
                :data="tableData"
                stripe
                style="width: 100%"
                height="calc(100% - 35px)"
            >
                >
                <el-table-column
                    label="选择"
                    width="55"
                >
                    <template slot-scope="scope">
                        <el-radio
                            v-model="radio"
                            :label="scope.row"
                            @change.native="getTemplateRow(scope.$index,scope.row)"
                        >
                            <i></i>
                        </el-radio>
                    </template>
                </el-table-column>
                <el-table-column
                    label="信息项名称"
                >
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.name" placeholder="请输入内容"></el-input>
                    </template>
                </el-table-column>
                <el-table-column
                    label="信息项编码"
                >
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.code" placeholder="请输入内容"></el-input>
                    </template>
                </el-table-column>
                <el-table-column
                    label="信息项类型"
                >
                    <template slot-scope="scope">
                        <el-select
                            v-model="scope.row.info_type"
                            placeholder="请选择信息项类型"
                            disabled
                        >
                            <el-option label="普通" :value="0"></el-option>
                            <el-option label="复合" :value="1"></el-option>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column
                    label="信息项说明"
                >
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.remarks" placeholder="请输入内容"></el-input>
                    </template>
                </el-table-column>
                <el-table-column
                    label="显示顺序"
                >
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.in_entity_order" placeholder="请输入内容"></el-input>
                    </template>
                </el-table-column>
            </el-table>
            <div class="table-page">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pagetion.pageindex"
                    :page-sizes="pagetion.pagesizes"
                    :page-size="pagetion.pagesize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pagetion.total"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        extr: {
            type: Object,
        },
    },
    data() {
        return {
            tableData: [],
            pagetion: {
                pageindex: 1,
                pagesize: 10,
                total: 0,
                pagesizes: [10, 20, 30, 40, 50, 100],
            },
            selectData: null, // 多选选中数据集合
            radio: '', // 单选
            treeData: [],
            defaultProps: {
                children: 'children',
                label: 'name',
            },
            defaultKey: [], // 默认展开节点id集合
            currentNodeKey: '', // 当前节点key
            currentNode: {}, // 当前节点数据
            entityId: '',
        };
    },
    watch: {
    },
    computed: {},
    methods: {
        // 展开节点
        expandtree(node) {
            node.expanded = !node.expanded;
            if (node.expanded) {
                this.defaultKey = [];
                this.defaultKey.push(node.data.id);
            } else {
                this.defaultKey = [];
            }
        },
        // 拉取业务分组树
        getTree() {
            this.$axios
                .get('/interfaceApi/datacenter/metadata/directory/gettree?parent_code=0')
                .then(res => {
                    this.treeData = res;
                    const obj = this.getFirstLeafNode(res);
                    this.$emit('load-data', obj.entity_id);
                    this.defaultKey = [];
                    this.defaultKey.push(obj.id);
                    this.currentNodeKey = obj.id;
                    this.$nextTick(() => {
                        this.$refs.tree.setCurrentKey(obj.id);
                    });
                    this.entityId = obj.entity_id;
                    this.getEntityInfo();
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 递归查找第一个叶子节点
        getFirstLeafNode(data) {
            let obj = null;
            if (data && data.length > 0) {
                const item = data[0];
                if (item.children.length === 0) {
                    obj = item;
                } else {
                    obj = this.getFirstLeafNode(item.children);
                }
            }
            return obj;
        },
        // 新增完成更新树
        handleCallBack() {
            this.getTree();
        },
        // 加载表格
        loadTable(id) {
            // this.currentNodeKey = id;
            // this.$emit('load-data', id);
            this.entityId = id;
            this.getEntityInfo();
        },
        // 获取信息项
        getEntityInfo() {
            this.$axios
                .get('/interfaceApi/datacenter/metadata/entity/info/edit/getpage/' + this.entityId
                + '?pageindex=' + this.pagetion.pageindex + '&pagesize=' + this.pagetion.pagesize)
                .then(res => {
                    if (res) {
                        this.pagetion.total = res.total;
                        this.tableData = res.rows;
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 切换页码
        handleCurrentChange(page) {
            this.pagetion.pageindex = page;
            this.getEntityInfo();
        },
        // 切换每页条数
        handleSizeChange(size) {
            this.pagetion.pagesize = size;
            this.getEntityInfo();
        },
        // 单选事件
        getTemplateRow() {
            this.selectData = this.radio;
            if (this.selectData) {
                this.extr.closeCall(this.selectData);
                window.hideToast();
            } else {
                this.$message.warning('请选择信息项！');
            }
        },
    },
    created() {
        this.getTree();
    },
    mounted() {},
};
</script>
<style lang="stylus">
.infoTable
    padding .1rem
    height 100%
    .slider
        width 2.2rem
        height 100%
        background #fff
        overflow hidden
        float left
        .new-module
            height .44rem
            background #F8F8F8
            border-bottom 1px solid #D7D7D7
            line-height .44rem
            color #333
            cursor pointer
            .iconxianshi_tianjia
                margin 0 .09rem 0 .12rem
            &:hover
                color #5588F1
        .tree-con
            height calc(100% - .54rem)
            overflow-y auto
            overflow-x auto
            .slot-t-node
                line-height .38rem
                height .38rem
                .parent-node
                    .iconzhankai
                        color #5588F1
                    .iconshouqi
                        color #F19F26
                    .iconwenjianjia-shouqi
                        color #5588F1
                    .iconwenjianjia-zhankai
                        color #F19F26
                    .iconfont
                        margin-right .1rem
                .leaf-node
                    color #666666
                    .iconlingxing
                        color #979EA7
                        margin 0 .1rem 0 0
                        font-size .14rem
    .table-con
        float left
        width calc(100% - 2.4rem)
        margin-left .2rem
        height calc(100% - 32px)
        .el-table
            border 1px solid #f0f0f0
            border-radius 2px
            thead
                color #022782
                background #EDF0F5
                th
                    padding .06rem 0
                    background #EDF0F5
                    text-align center
            tr
                td
                    padding .06rem 0
                    border-bottom 0
                    text-align center
                &.el-table__row--striped
                        background #f8fafd
        .table-page
            text-align right
            margin-top .1rem
</style>
<style>
.infoTable .slider .el-icon-caret-right:before{
    display: none!important;
}
.infoTable .slider .el-tree-node__content{
    height: .36rem;
}
.infoTable .slider .tree-con .is-current>.el-tree-node__content .slot-t-node .leaf-node{
    color: #FE7903;
}
.infoTable .slider .tree-con .is-current>.el-tree-node__content .slot-t-node .leaf-node .iconlingxing{
    color: #FE7903;
}
.infoTable .slider .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content{
    background-color:#E2EAF0;
}
</style>